import React, {useEffect} from "react";
import "./../../styles/myAssessmentsCard.css";

import { useNavigate } from "react-router-dom";

function MyAssessmentsCard ({name,assessmentId,inscriptionGuid,learningCourseId}) {

    const navigate = useNavigate();

    return (
        <div className="my-assessments-card-main-container" onClick ={() => navigate(`/assessment/aid/${assessmentId}/iid/${inscriptionGuid}`)}>
            <div className="my-assessments-card-inner-container" >
                <h1 className="my-assessments-card-title">{name}</h1>
            </div>
        </div>
    );
}

export {MyAssessmentsCard}