import React, {useEffect,useCallback} from "react";
import  {createPortal}  from "react-dom";
import "./../../styles/allQuestionsModal.css";

import closeIcon from "./../../imgs/ActionsIcons/close.svg";
import expandIcon from "./../../imgs/ActionsIcons/expand.svg";
import bmSelected from "./../../imgs/ActionsIcons/bookmark-selected.svg";

import { ModalQuestionEntry } from "./ModalQuestionEntry";


// import { ToogleContext } from "../../hooks/ToogleContext";
// import { getGraphQl } from "../../hooks/ApiCalls";

function AllQuestionsModal ({userQuestionList, onCloseAllQuestions, onQuestionSelected}) {

    const [allQuestionsStatus, setallQuestionsStatus] = React.useState("");
    const [tabSelected, settabSelected] = React.useState([true,false]);

    const handleNavBookMark = (tab) => {
        settabSelected([false,true])
    };

    const handleNavAllQuestions = (tab) => {
        settabSelected([true,false])
    };

    const escFunction = useCallback((event) => {
        if (event.key === "Escape") {
            onCloseAllQuestions();
        }
      }, []);
    
      useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
      }, [escFunction]);

    return createPortal(
        <div className="All-questions-main-container">
            <div className="All-questions-inner-container">
                <div className="All-questions-cancel-header-container">
                    <div></div>
                    <button className="inscription-detail-btn-cancel" onClick={onCloseAllQuestions}><img src={closeIcon} className="All-questions-close-icon"/> </button>
                </div>
                
                <div className="All-questions-nav-btns-container">
                    <div className="All-questions-nav-btns-tabs">
                        <div><h1 className={`All-questions-nav-btns-text-left ${tabSelected[0] && "active"}`} onClick={handleNavAllQuestions}>All Questions</h1></div>
                        <div className="All-questions-nav-btns-bookmark-container">
                            <img className="Al-questions-nav-btns-bookmark-icon" src= {bmSelected}/>
                            <h1 className={`All-questions-nav-btns-text-right ${tabSelected[1] && "active"}`} onClick={handleNavBookMark} >Bookmarked</h1>
                            <h1 className="All-questions-nav-btns-bookmark-count">0</h1>
                        </div>
                    </div>
                    <div className="All-questions-nav-btns-expand-icon">
                        <img src={expandIcon} className="All-questions-expand-icon"/>
                    </div>
                </div>

                {                   
                    userQuestionList.map( (question, index) => (
                        <ModalQuestionEntry
                            key={index}
                            questionNumber={index}
                            isBookMarked={question.isBookmark}
                            isAnswered={question.isAnswered}
                            onQuestionSelected={onQuestionSelected}
                            onCloseAllQuestions={onCloseAllQuestions}
                        />
                    ))
                }
    
            </div>           
        </div>
        ,
        document.getElementById("modal")
    );
}

export {AllQuestionsModal};