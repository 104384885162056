import React, {useEffect,useState} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./../styles/assessment.css";

import logo from "./../imgs/Brand/logo-alterno.png"

import {Spinner} from "../components/Spinner";
import { QuizAppView } from "../components/QuizApp/QuizAppView";
import { getGraphQl } from "../hooks/ApiCalls";

import useToken from "../hooks/useToken";
import Moment from "moment";

function Assessment () {
    
    let assessmentUserInfo = useParams();
    const navigate = useNavigate();

    const {token, setToken} = useToken();

    const [instructionStep, setinstructionStep] = useState(0);
    const [assessmentInfo, setassessmentInfo] = useState("");

    let graphQuery = `
        query {
            GetAssessmentbyId(Assessment: {assessmentId: "${assessmentUserInfo.assessmentId}"}) {
              id
              name
              questionsByAttemp
              duration
              passPorcentage
            }
          }
    `;

    const handleAssessmentAttemp = async () => {

        let graphQueryAsseesmentAttemps = `
        query {
            GetOpenAssessmentAttemp(AssessmentAttemp:{inscriptionGuid:"${assessmentUserInfo.inscriptionGuid}"}) {
              assessmentAttempId
              name
              startTime
            }
          }
        `;

        const {data:{data:{GetOpenAssessmentAttemp}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQueryAsseesmentAttemps});

        if (GetOpenAssessmentAttemp) {
            navigate(`/attemp/${GetOpenAssessmentAttemp.assessmentAttempId}`);

        } else {
            let graphQueryCreateAsseesmentAttemps = `
                mutation {
                    createAssessmentAttemp (AssessmentAttemp: {inscriptionGuid:"${assessmentUserInfo.inscriptionGuid}",assessmentId:"${assessmentUserInfo.assessmentId}", name:"${assessmentInfo.name}", startTime:"${Moment().format()}"}) {
                    message
                    status
                    success
                    }
                }
            `;

            const {data:{data:{createAssessmentAttemp}}} = await getGraphQl({url: /*"http://localhost:3001/api"*/"https://back.doraglobaltrade.com/api", query: graphQueryCreateAsseesmentAttemps});
            if (createAssessmentAttemp?.assessmentAttempId){navigate(`/attemp/${createAssessmentAttemp.assessmentAttempId}`);}
        }

    };

    const handleNextStep = () => {
        setinstructionStep(instructionStep + 1);
    };

    const handleBackStep = () => {
        setinstructionStep(instructionStep - 1);
    };

    useEffect(() => {

        (async () => {
            const {data:{data:{GetAssessmentbyId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setassessmentInfo(GetAssessmentbyId);
        })();

      }, []);

    return(
        <React.Fragment>
            
            <div className="quiz-app-main-container">
                <div className="quiz-app-inner-container">
                    <img src={logo} className="quiz-app-logo"/>
                    <h1 className="quiz-app-quiz-title"> {assessmentInfo.name} </h1>
                    
                    { instructionStep === 0 ?
                        <React.Fragment>
                            <div className="quiz-app-quiz-instructions-container">
                                <h2 className="quiz-app-quiz-subtitle">Instrucciones</h2>
                                <li className="quiz-app-instruction-item">Numero de preguntas: <label className="quiz-app-instruction-label">{assessmentInfo.questionsByAttemp}</label></li>
                                <li className="quiz-app-instruction-item">Tiempo límite: <label className="quiz-app-instruction-label">00:{assessmentInfo.duration}:00</label></li>
                                <li className="quiz-app-instruction-item">Tiene que ser finalizado en un intento: <label className="quiz-app-instruction-label">No puedes guardar y continuar luego</label></li>
                                <li className="quiz-app-instruction-item">Preguntas por página: <label className="quiz-app-instruction-label">1</label></li>
                                <li className="quiz-app-instruction-item"><label className="quiz-app-instruction-label">Podrás volver y cambiar respuestas</label></li>
                                <li className="quiz-app-instruction-item"><label className="quiz-app-instruction-label">No podrás terminar el examen sin haber respondido todas las preguntas</label></li>
                                <li className="quiz-app-instruction-item">Puntaje para aprobar: <label className="quiz-app-instruction-label">{assessmentInfo.passPorcentage}%</label></li>
                            </div>
                                
                            <button className="quiz-app-instruction-btn" onClick={handleNextStep}>Siguiente</button>
                        </React.Fragment>
                        :

                        instructionStep === 1 ? 
                            <div className="quiz-app-instructions-copy-rights-container">
                                <p className="quiz-app-copy-rights-text">
                                    Ninguna parte de este Simulador puede ser reproducido, distribuido o transmitido de alguna forma ya sea fotocopiando, grabando o por cualquier otro método mecánico o electrónico 
                                    sin la autorización previa de manera escrita de Agilexcellence. La violación de está política puede resultar en acciones legales en su contra y su IP podría ser bloqueada para futuros éxamenes y cursos.
                                </p>
                                <div className="quiz-app-copy-rights-btns">
                                    <button className="quiz-app-instruction-btn" onClick={handleBackStep}>Anterior</button>
                                    <button className="quiz-app-instruction-btn" onClick={handleNextStep}>Empezar Assessment</button>
                                </div>
                            </div>
                        :
                        
                        handleAssessmentAttemp() ? <Spinner/>: <h1>Error parce</h1>
                    }
                </div>
            </div>
            
       </React.Fragment>
    );
}

export { Assessment };