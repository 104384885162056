import React from "react";
import "./../styles/resultsPage.css";
import { useParams } from "react-router-dom";

import logo from "./../imgs/Brand/logo-alterno.png";
import { ResultsView } from "../components/QuizApp/ResultsView";


function Results () {

    let assessmentAttempId = useParams();

    return(
        <div className="results-page-main-container">
            <img src={logo} className="quiz-app-logo"/>
            <ResultsView/>
        </div>
    );

}

export {Results}