import React from "react";

const CheckBoxQuestionOptions = ({userQuestionList, QuestionNumber, QuestionList, onUserQuestionList, answers}) => {

    const handleOptionCheckboxChange = ({ target: { value }}) => {
        const questions = [...userQuestionList];
        const position = questions?.at(QuestionNumber-1)?.answer?.findIndex((element) => {
            return element === +value;
        });

        if(position === -1) {
            questions.at(QuestionNumber-1).answer.push(+value);
        } else {
            questions?.at(QuestionNumber-1)?.answer?.splice(position, 1);
        }
        

        if(questions.at(QuestionNumber-1)?.answer?.length) {
            questions.at(QuestionNumber-1).isAnswered = true;

            let isCorrect = true;
            QuestionList?.at(QuestionNumber-1)?.answers?.forEach((element, index) => {
                if(element?.isCorrect){
                    if(!userQuestionList?.at(QuestionNumber-1)?.answer?.includes(index)) {
                        isCorrect = false;
                    }
                } else {
                    if(userQuestionList?.at(QuestionNumber-1)?.answer?.includes(index)) {
                        isCorrect = false;
                    }
                } 
            });

            questions.at(QuestionNumber-1).isCorrect = isCorrect;
        }else {
            questions.at(QuestionNumber-1).isAnswered = false;
        }
        onUserQuestionList(questions);
    }

    return answers?.map(({OpText}, index) => {
        return (
            <>
                <input
                    type="checkbox"
                    name={index}
                    value={index}
                    id={index}
                    checked={userQuestionList?.at(QuestionNumber-1)?.answer.includes(index)}
                    onChange={handleOptionCheckboxChange}
                />

                <label htmlFor={index}>{OpText}</label>
                <br></br>
            </>
        )
    }) 
}

export default CheckBoxQuestionOptions;