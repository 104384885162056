import { faBullseye } from '@fortawesome/free-solid-svg-icons';
import React, { useState }  from 'react';
// import { useLocalStorage } from './useLocalStorage';

const ToogleContext = React.createContext();

function ToogleProvider(props) {

const [token, setToken] = useState();
const [assessmentName, setassessmentName] = React.useState("");
const [assessmentId_global, setassessmentId_global] = React.useState("");
const [inscriptionGuid_global, setinscriptionGuid_global] = React.useState("");
const [learningCourseId_global, setlearningCourseId_global] = React.useState("");

const [QuestionList, setQuestionList] = useState([{description:"Refrescar página"},{description:"Refrescar página"}]);
const [userQuestionList, setuserQuestionList] = useState([]);

const [openQuoteModal, setopenQuoteModal] = React.useState(false);
const [courseList, setcourseList] = React.useState([]);
const [userLocation, setuserLocation] = React.useState([false,false,false,false,false,false,false,false]);
const [openUserDetails, setopenUserDetails] = React.useState(false);


return (
    <ToogleContext.Provider value={{
      token,
      setToken,
      assessmentName,
      setassessmentName,
      assessmentId_global,
      setassessmentId_global,
      inscriptionGuid_global,
      setinscriptionGuid_global,
      learningCourseId_global,
      setlearningCourseId_global,

      openQuoteModal,
      setopenQuoteModal,

      courseList,
      setcourseList,
      
      userLocation,
      setuserLocation,

      openUserDetails,
      setopenUserDetails,

      QuestionList,
      setQuestionList,

      userQuestionList,
      setuserQuestionList,
    }}>
      {props.children}
    </ToogleContext.Provider>
  );

  }

export { ToogleContext, ToogleProvider };