import React, {useEffect, useState} from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "../../styles/quizAppView.css"

import logo from "../../imgs/Brand/logo-alterno.png"

import bmIcon from "./../../imgs/ActionsIcons/bookmark.svg";
import bmIconSelected from "./../../imgs/ActionsIcons/bookmark-selected.svg";
import AllQIcon from "./../../imgs/ActionsIcons/all_question.svg";

import { getGraphQl } from "../../hooks/ApiCalls";
import { convertToArray } from "../../utils/utils";
import { AllQuestionsModal } from "./AllQuestionsModal";
import RadioQuestionOptions from './RadioQuestionOptions';
import CheckBoxQuestionOptions from './CheckBoxQuestionOptions';

import {ToogleContext} from "./../../hooks/ToogleContext";

function QuizAppView () {

    let assessmentAttempId = useParams();
    const navigate = useNavigate();
    
    const [AssessmentInfo, setAssessmentInfo] = useState("");
    const [openAllQuestions, setopenAllQuestions] = useState(false);

    const [time,setTime] = useState(0);
    const [TimeBox, setTimeBox] = useState(0);

    // const [QuestionList, setQuestionList] = useState([{description:"Refrescar página"},{description:"Refrescar página"}]);
    // const [userQuestionList, setuserQuestionList] = useState([]);

    const {QuestionList, setQuestionList} = React.useContext(ToogleContext);
    const {userQuestionList, setuserQuestionList} = React.useContext(ToogleContext);
    
    const [QuestionNumber, setQuestionNumber] = useState(1);
    const [AttempScore, setAttempScore] = useState(0);

    let graphQuery = `
        query {
            GetAssessmentAttempbyId(AssessmentAttemp:{assessmentAttempId:"${assessmentAttempId.assessmentAttempId}"}) {
                assessmentId
                questionsObj
            }
        }
    `;

    const handleBookmark = () =>{
        const list = [...userQuestionList];
        list.at(QuestionNumber-1).isBookmark = !list.at(QuestionNumber-1).isBookmark;
        setuserQuestionList(list);
    };

    const handleNextQuestion = () =>{

        if(userQuestionList?.at(QuestionNumber-1)?.answer === -1 || userQuestionList?.at(QuestionNumber-1)?.answer?.length === 0) {
            alert("Debes responder la pregunta para poder continuar");
            return;
        }
        setQuestionNumber(QuestionNumber + 1);
    };

    const handleBackQuestion = () =>{
        setQuestionNumber(QuestionNumber -1);
    };

    const handleFinishAttemp = async () => {

        let isCompleted = true;
        let score = 0;

        userQuestionList.forEach(question => {
            if(!question.isAnswered){
                isCompleted = false;
            }

            if(question.isCorrect) {
                score++;
            }
        });

        score = (score/QuestionList.length)*100;
       
        if (isCompleted) {

            setAttempScore(score);

            let finishAttempQuery = `
            mutation {
                FinishAssessmentAttemp(AssessmentAttemp:{assessmentAttempId:"${assessmentAttempId.assessmentAttempId}",score:"${score}"}) {
                success
                status
                message
                }
            }
            `;

            const {data:{data:{FinishAssessmentAttemp}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: finishAttempQuery});

            if (FinishAssessmentAttemp.success) {
                alert("Terminaste el intento");            
                navigate(`/results/${assessmentAttempId.assessmentAttempId}`);

            } else {
                alert("Error al terminar el intento"); 
            }
            
        } else {
            alert("Aun tienes preguntas por responder");
        }
    }

    const handleOpenAllQuestions = () => {
        
        setopenAllQuestions(true);
    };

    const handleCloseAllQuestions = () => {
        setopenAllQuestions(false);
    };

    useEffect((time)=>{
        let timer = setInterval(()=>{
            setTime((time)=>{
                if (time ===0) {
                    clearInterval(timer);
                    return 0;
                } else return time -1;
            });
        },1000); 
    },[]);

    useEffect(() => {

        (async () => {
            
            const {data:{data:{GetAssessmentAttempbyId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            
            let QuestionsObj = JSON.parse(GetAssessmentAttempbyId.questionsObj);
            let QuestionArr = [];

            for (let question of QuestionsObj) {
                const newAnswers = convertToArray(question.answers);
                question.answers = JSON.parse(newAnswers);
                QuestionArr.push(question);
            }
    
            setQuestionList(QuestionArr);

            const list = QuestionArr.map( ({ questionId, isSingleAnswer }) => {

                return { questionId, answer: isSingleAnswer ? -1 : [], isCorrect: false, isBookmark: false, isAnswered: false }
            });
            setuserQuestionList(list);
            
            const {data:{data:{GetAssessmentbyId}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: `
                query{
                    GetAssessmentbyId(Assessment: {assessmentId: "${GetAssessmentAttempbyId.assessmentId}"}) {
                    name
                    questionsByAttemp
                    duration
                    }
                }
            `});

            setTime(Number(GetAssessmentbyId.duration)*60);
            setTimeBox(Number(GetAssessmentbyId.duration)*60);
            setAssessmentInfo(GetAssessmentbyId);

        })();

      }, [graphQuery]);

    const question = QuestionList[QuestionNumber - 1];

    return (
        <div className="quiz-app-view-main-container">
            <div className="quiz-app-view-inner-container">
                <img src={logo} className="quiz-app-logo"/>
                <h1 className="quiz-app-quiz-title"> {AssessmentInfo.name} </h1>
                <div className="app-quiz-app-see-all-questions-container">
                    <div className="quiz-app-see-all-questions-left-block">
                        <img src={AllQIcon} className="quiz-app-see-all-questions-icon"/>
                        <h2 className="quiz-app-see-all-questions-txt" onClick={handleOpenAllQuestions}> See all questions</h2>
                    </div>
                    <div><h1></h1></div>
                </div>
                
                <div className="quiz-app-timer-container">
                    <div className="quiz-app-timer-text">
                        <h2 className="quiz-app-timer-txt">Time left:</h2>
                        <h3 className="quiz-app-timer-time">{`${Math.floor(time/60)}`.padStart(2,0)}: {`${time % 60}`.padStart(2,0)}</h3>
                    </div>
                    <div className="app-quiz-timer-progress-bar-container">
                        <div style={{width:`${((TimeBox-(TimeBox-time))/TimeBox)*100}%`}} className="app-quiz-timer-progress-bar"></div> 
                    </div>
                </div>

                <div className="quiz-app-question-container">
                    <div className="quiz-app-question-container-header">
                        <div className="quiz-app-question-numbers">
                            <h2 className="quiz-app-question-numbers-text">Question {QuestionNumber} of {QuestionList.length}</h2>
                        </div>
                        <img src={userQuestionList?.at(QuestionNumber-1)?.isBookmark ? bmIconSelected : bmIcon} className="quiz-app-question-bookmark-icon" onClick={handleBookmark} />
                    </div>

                    <div className="quiz-app-question-main-container">
                        <p className="quiz-app-question-description">{QuestionList[QuestionNumber-1].description}</p>
                        
                        {question?.isSingleAnswer ? 
                            <RadioQuestionOptions 
                                userQuestionList={userQuestionList} 
                                QuestionNumber={QuestionNumber} 
                                QuestionList={QuestionList} 
                                onUserQuestionList={setuserQuestionList}
                                answers={question?.answers}
                            /> : 
                            <CheckBoxQuestionOptions  
                                userQuestionList={userQuestionList}
                                QuestionNumber={QuestionNumber}
                                QuestionList={QuestionList}
                                onUserQuestionList={setuserQuestionList}
                                answers={question?.answers}
                            />
                        }

                    </div>

                    <div className="quiz-app-view-btns">
                        {   
                            QuestionNumber > 1 ? <button className="quiz-app-view-btn" onClick={handleBackQuestion}>Back</button> : <div></div>
                        }
                        {
                            QuestionNumber < QuestionList.length ? <button className="quiz-app-view-btn" onClick={handleNextQuestion}>Next</button> : ""
                        }
                        {
                            QuestionNumber == QuestionList.length ? <button className="quiz-app-view-btn-finish" onClick={handleFinishAttemp}>Finish attemp</button>:""
                        }
                    </div>

                </div>
            </div>
            
            {openAllQuestions &&  <AllQuestionsModal userQuestionList= {userQuestionList} onCloseAllQuestions={handleCloseAllQuestions} onQuestionSelected={setQuestionNumber} />}
        </div>
    );
}

export {QuizAppView};