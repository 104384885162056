import React, {useEffect, useState} from "react";
import "./../../styles/resultsView.css";

import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { getGraphQl } from "./../../hooks/ApiCalls";

import warningIcon from "./../../imgs/ActionsIcons/warning.svg";

import {ToogleContext} from "./../../hooks/ToogleContext";
import {ResultQuestionDetail} from "./ResultQuestionDetail";

function ResultsView () {

    const navigate = useNavigate();
    let assessmentAttempId = useParams();

    const [assessmentResults, setassessmentResults]= useState();
    const {QuestionList, setQuestionList} = React.useContext(ToogleContext);
    const {userQuestionList, setuserQuestionList} = React.useContext(ToogleContext);

    const handleReturnAssessments = ()=> {
        navigate(`/my-assessments`);
        setQuestionList([{description:"Refrescar página"},{description:"Refrescar página"}]);
        setuserQuestionList([]);
    };

    let graphQuery = `
        query {
            GetAssessmentAttempResults(AssessmentAttemp:{assessmentAttempId:"${assessmentAttempId.assessmentAttempId}"}) {
            name
            assessmentId
            score
            approved
            startTime
            endTime
            duration
            }
        }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetAssessmentAttempResults}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setassessmentResults(GetAssessmentAttempResults);
        })();

      }, []);

    return(
        <React.Fragment>

            <div className="results-view-final-note-container">
                <div className="results-view-final-note-inner-container">
                    <img src={warningIcon} className="results-view-final-note-icon" alt="warning icon"/>
                    <div className="results-view-final-note-box">
                        <p className="results-view-final-note-text"><label className="results-view-final-note-label">Note:</label> This Test was automatically finished when either:</p>
                        <p className="results-view-final-note-text">1. Its time limit was reached when taking the Test.</p>
                        <p className="results-view-final-note-text">2. An attempt to resume this Test was not allowed due to date or time limits.</p>
                    </div>
                </div>
            </div>

            <div className="results-view-main-container">
                <div className="results-view-inner-container">
                    <div className="results-view-results-box">
                        <h1 className="results-view-container-title">{assessmentResults?.name}</h1>
                        <div className="results-view-box-items">
                            <div className="results-view-box-item">
                                <h2 className="results-view-box-title">Percentage:</h2>
                                <p className="results-view-box-text">{assessmentResults?.score}%</p>
                            </div>
                            <div className="results-view-box-item">
                                <h2 className="results-view-box-title">Duration:</h2>
                                <p className="results-view-box-text">00:{assessmentResults?.duration}:00</p>
                            </div>
                            <div className="results-view-box-item">
                                <h2 className="results-view-box-title">Date started:</h2>
                                <p className="results-view-box-text">{assessmentResults?.startTime}</p>
                            </div>
                            <div className="results-view-box-item">
                                <h2 className="results-view-box-title">Date finished:</h2>
                                <p className="results-view-box-text">{assessmentResults?.endTime}</p>
                            </div>
                        </div>
            
                        {
                            assessmentResults?.approved ?
                                <div className="results-view-box-feedback-container-success">
                                    <div className="results-view-box-feeback-inner-container">
                                        <p className="results-view-box-text">Thank you for taking the Open assessment of Scrum. Congratulations, you have passed the assessment.</p>
                                        <br/>
                                        <p className="results-view-box-text">Agilexcellence</p>
                                    </div>
                                </div>
                            :
                                <div className="results-view-box-feedback-container">
                                    <div className="results-view-box-feeback-inner-container">
                                        <p className="results-view-box-text">Thank you for taking the Open assessment of Scrum. Please review the Scrum Guide on Scrum.org and take this assessment again to improve your score.</p>
                                        <br/>
                                        <p className="results-view-box-text">Agilexcellence</p>
                                    </div>
                                </div>
                        }
                        
                    </div>
                </div>
            </div>

            {                   
                QuestionList.map( (question, index) => (
                   
                    <ResultQuestionDetail
                        key={index}
                        questionNumber={index}
                        description={question.description}
                        userQuestionList={userQuestionList}
                        isBookMarked={userQuestionList[index].isBookmark}
                        isAnswered={question.isAnswered}
                        answers = {question.answers}
                        isSingleAnswer={question.isSingleAnswer}
                        isCorrect = {userQuestionList[index].isCorrect}
                    />
                ))
            }

            <div className="results-view-continue-container">
                <div className="results-view-continue-inner-container">
                    <h1 className="results-view-container-title">Continue here</h1>
                    <button className="results-view-continue-btn" onClick={handleReturnAssessments}>Return to My assessment</button>
                </div>
            </div>


        </React.Fragment>
    );
}

export {ResultsView}