import React from "react";
import Login from "../components/Login";

import useToken from "../hooks/useToken";
// import { LoginAs } from "./LoginAs";
import { MyAssessmentsView } from "./../components/MyAssessments/MyAssessmentsView";
import { LoginAs } from "./LoginAs";

function MyAssessments() {

  const {token, setToken} = useToken();

  if (!token){
    return <Login/>
  }

  return (
    <MyAssessmentsView/>
  );

}

export {MyAssessments};