import React from "react";

import "./styles/App.css";
import "./styles/root.css";

import { createHashRouter, RouterProvider } from "react-router-dom";
import { ToogleProvider } from "./hooks/ToogleContext";
import LoginPage from "./pages/LoginPage";
import { LoginAs } from "./pages/LoginAs";
import { Assessment } from "./pages/Assessment";
import { MyAssessments } from "./pages/MyAssessments";
import { QuizAppView } from "./components/QuizApp/QuizAppView";
import { Results } from "./pages/Results";

const router = createHashRouter ([
  {
    path: "/",
    element: <LoginPage/>,
  },
  {
    path: "/login-as",
    element: <LoginAs/>,
  },
  {
    path: "/my-assessments",
    element: <MyAssessments/>,
  },
  {
    path: "/assessment/aid/:assessmentId/iid/:inscriptionGuid",
    element: <Assessment/>,
  },
  {
    path: "/attemp/:assessmentAttempId",
    element: <QuizAppView/>,
  },
  {
    path: "/results/:assessmentAttempId",
    element: <Results/>,
  },

]);

function App() {

  return (
    <ToogleProvider>
      <RouterProvider router = {router}/>
    </ToogleProvider>
   
  );
}

export default App;
