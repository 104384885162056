import React from "react";

const RadioQuestionOptions = ({ userQuestionList, QuestionNumber, QuestionList, onUserQuestionList, answers}) => {

    const handleOptionChange = ({ target: { value }}) => {
        const questions = [...userQuestionList];
        questions.at(QuestionNumber-1).answer = +value;
        questions.at(QuestionNumber-1).isAnswered = true;
        questions.at(QuestionNumber-1).isCorrect = QuestionList.at(QuestionNumber-1)?.answers?.at(+value)?.isCorrect;
        onUserQuestionList(questions);
    }

    return answers?.map(({OpText}, index) => {
        return (
            <>
                <input
                    type="radio"
                    name={index}
                    value={index}
                    id={index}
                    checked={userQuestionList?.at(QuestionNumber-1)?.answer === index}
                    onChange={handleOptionChange}
                />
                <label htmlFor={index}>{OpText}</label>
                <br></br>
            </>
        )
    })
}

export default RadioQuestionOptions;