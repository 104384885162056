import React from "react";
import "../../styles/resultQuestionDetail.css";

import bookmark from "./../../imgs/ActionsIcons/bookmark.svg";
import bmSelected from "./../../imgs/ActionsIcons/bookmark-selected.svg";
import check from "./../../imgs/ActionsIcons/confirm.svg";
import cancel from "./../../imgs/ActionsIcons/cancel.svg";

import  RadioQuestionOptions from "./RadioQuestionOptions";
import  CheckBoxQuestionOptions from "./CheckBoxQuestionOptions";

function ResultQuestionDetail ({questionNumber,description,userQuestionList,isSingleAnswer,answers, QuestionList, isAnswered,isBookMarked, isCorrect}) {
    console.log(isBookMarked)
    return(
        <div className="result-question-detail-main-container">
            <div className="results-question-detail-inner-container">

                <div className="results-question-detail-question-header">
                    <div className="results-question-detail-question-header-left">
                        <h1 className="result-question-detail-question-number">Question {questionNumber + 1 }</h1>
                        <img src={isBookMarked? bmSelected : bookmark} className="result-question-detail-bookmark-icon"/>
                    </div>
                    <div className="results-question-detail-question-header-right">
                        <h1 className={`result-question-detail-question-result ${isCorrect ? "result-question-detail-question-result" : "result-question-detail-question-result-wrong"}`}>{isCorrect ? "Correct" : "Incorrect"} </h1>
                        <img src={isCorrect ? check : cancel} className="result-question-detail-bookmark-icon"/>
                    </div>
                </div>
                
                <p className="result-question-detail-question-description">{description}</p>
                
                {                   
                    answers.map( (answer, index) => ( 
                        isSingleAnswer ?
                        <>
                            <div className="result-question-detail-option-container">
                                <input
                                    type="radio"
                                    name={index}
                                    value={index}
                                    id={index}
                                    checked={userQuestionList?.at(questionNumber)?.answer === index}
                                />
                                <label className="result-question-detail-question-options" htmlFor={index}>{answer.OpText}</label>
                            </div>
                            <br></br>
                        </>
                        :
                        <>
                            <div className="result-question-detail-option-container">
                                
                                <input
                                    type="checkbox"
                                    name={index}
                                    value={index}
                                    id={index}
                                    checked={userQuestionList?.at(questionNumber)?.answer.includes(index)}
                                />
                                <label className="result-question-detail-question-options" htmlFor={index}>{answer.OpText}</label>
                            </div>
                            <br></br>
                        </>
                    ))
                }
            </div>
        </div>
    );
}

export {ResultQuestionDetail};