import React, {useContext, useState, useEffect} from "react";
import "./../../styles/myAssessmentsView.css"

import logo from "./../../imgs/Brand/logo-alterno.png"
import { MyAssessmentsCard } from "./MyAssessmentsCard";

import useToken from "./../../hooks/useToken";
import { getGraphQl } from "./../../hooks/ApiCalls";

function MyAssessmentsView () {

    const {token, setToken} = useToken();
    const [openAsssessmentsList, setopenAsssessmentsList]= useState([]);
    const [name, setname]= useState("");
    
    let graphQuery = `
        query {
            GetMyAssignedAssessments(MyAssessments: {userToken: "${token}"}) {
              nickName
              learningCourseId
              inscriptionGuid
              productId
              assessmentId
              name
            }
          }
    `;

    useEffect(() => {

        (async () => {
            const {data:{data:{GetMyAssignedAssessments}}} = await getGraphQl({url:"https://back.doraglobaltrade.com/api", query: graphQuery});
            setopenAsssessmentsList(GetMyAssignedAssessments);
            setname (GetMyAssignedAssessments[0].nickName)
            console.log(GetMyAssignedAssessments)
        })();

      }, []);

    return(
        <React.Fragment>
            <div className="my-assessments-view-main-container">
                <img src={logo} className="quiz-app-logo"/>
                <h1 className="my-assessments-view-welcome-title">Welcome {name},</h1>
                <h2 className="my-assessments-view-welcome-subtitle">Here you have your assigned assessments</h2>
                <div className="my-assessments-view-cards-container">

                {   
                    openAsssessmentsList.map( (assessment) => (
                        <MyAssessmentsCard
                            key={assessment.inscriptionGuid} 
                            name={assessment.name}
                            assessmentId={assessment.assessmentId}
                            inscriptionGuid={assessment.inscriptionGuid}
                            learningCourseId={assessment.learningCourseId}
                        />
                    ))
                }
                 
                </div>
            </div>
        </React.Fragment>
    )
}

export {MyAssessmentsView};