import React from "react";
import "../../styles/modalQuestionEntry.css";

import rightRow from "./../../imgs/ActionsIcons/arrow-right.svg";
import downRow from "./../../imgs/ActionsIcons/arrow-bottom.svg";
import upRow from "./../../imgs/ActionsIcons/arrow-top.svg";
import bookmark from "./../../imgs/ActionsIcons/bookmark.svg";
import bmSelected from "./../../imgs/ActionsIcons/bookmark-selected.svg";

function ModalQuestionEntry ({questionNumber, isAnswered,isBookMarked, onQuestionSelected, onCloseAllQuestions}) {


    const handleiconClick = () => {
        onCloseAllQuestions();
        onQuestionSelected(questionNumber + 1);
    }

    return(
        <div className="All-questions-entry-container">
            <div className="All-questions-left-box">
                <img src={isBookMarked ? bmSelected : bookmark} className="All-questions-entry-bookmark-icon"/>
                <h1 className="All-questions-entry-question-number">Question {questionNumber + 1 }</h1>
                <img src={downRow} className="All-questions-entry-expand-icon"/>
                <label className= {`All-questions-answer-label ${isAnswered && "answered"}`}> {isAnswered ? "Answered":  "Unanswered"}</label>
            </div>
            <img src={rightRow} className="All-questions-entry-go-icon" onClick={handleiconClick}/>
        </div>
    );
}

export {ModalQuestionEntry};